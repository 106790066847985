var render, staticRenderFns
import script from "./terminal_scan_list.vue?vue&type=script&lang=js&"
export * from "./terminal_scan_list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('006c8ad8')) {
      api.createRecord('006c8ad8', component.options)
    } else {
      api.reload('006c8ad8', component.options)
    }
    
  }
}
component.options.__file = "found/views/mdm/views/dealer_associated_scanning_data/form/terminal_scan_list.vue"
export default component.exports